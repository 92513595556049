<template>


  <v-col sm="12" class="main-body tw-mt-20  lg:tw-mt-0 tw-mx-2 tw-my-5 lg:tw-m-0">
    <div class="dashboard">
      <p class="dashboard-header" :style="{color:companyData.companyColor}">Hi {{staffData.firstname}}.</p>
      <p class="dashboard-sub-header">{{staffData.terminalAssignedName || staffData.terminalAssigned}}</p>
    </div>
    <div class="tw-flex tw-flex-col tw-pt-3">
      <div class="tw-flex first-section">
        <v-col sm="12" lg="9" class="padding-zero">
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between" v-if="userData.userRole === 'transportCompanyManager'">
            <icon-and-text
              :icon="tripPlus"
              title="Add Trips"
              desc="Trips"
              :width="$vuetify.breakpoint.mdAndUp ? '24%' : '100%'"
              @clickAction="routeToAddTrip"
            />
            <icon-and-text
              :icon="fleetPlus"
              title="View Trips"
              desc="Trips"
              :width="$vuetify.breakpoint.mdAndUp ? '24%' : '100%'"
              @clickAction="routeToAllTrip"
            />
            <icon-and-text
              :icon="adminPlus"
              title="Add an Admin"
              desc="Booking Admin"
              :width="$vuetify.breakpoint.mdAndUp ? '24%' : '100%'"
              @clickAction="routeToAddBookingAdmin"
            />
            <icon-and-text
              :icon="pilotPlus"
              title="View Admins"
              desc="Booking Admins"
              :width="$vuetify.breakpoint.mdAndUp ? '24%' : '100%'"
              @clickAction="routeToAllBookingAdmin"
            />
          </div>

         <manager-card/>

          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between " :class="[userData.userRole === 'transportCompanyBookingAdmin' ? '': 'tw-mt-7']">
            <div  class="tw-bg-white tw-rounded-md table-container-div section-divider tw-p-4 tw-mb-5 lg:tw-mb-0" >
              <div class="table-container">
                <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                  <p
                      :style="{ color: companyData.companyColor }"
                      class="table-container-header mb-0 tw-px-0"
                  >
                    Booking Admin
                  </p>
                  <p @click="routeToAllBookingAdmin" :style="{ color: companyData.companyColor }"
                     class="table-container-header mb-0 tw-px-0 tw-cursor-pointer">
                    View
                  </p>
                </div>
                <loader :color="companyData.companyColor" :loading="companyAdminLoading" v-if="companyAdminLoading"/>
                <div v-else  :class="[userData.userRole === 'transportCompanyBookingAdmin' ? 'route-card': 'booking-admin-card']">
                  <div class="tw-flex tw-flex-col tw-w-full " v-for="(admin, index) in companyBookingAdmins" :key="index">
                    <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                      <img class="img-css" :src="admin.profileImage" alt="image">
                      <h6 class="desc-text">{{admin.firstname && $vuetify.breakpoint.smAndUp
                          ? admin.firstname : admin.firstname.substring(0,6)+'...'}}</h6>
                      <h6 class="desc-text">+{{admin.phoneNumber}}</h6>
                      <h6 class="desc-text">{{admin.terminalAssigned && admin.terminalAssigned.length &lt; 6
                          ? admin.terminalAssigned : admin.terminalAssigned.substring(0,6)+'...'}}</h6>
                      <v-menu bottom offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <td class="d-flex justify-center desc-text">
                            <v-btn v-bind="attrs" v-on="on" icon>
                              <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </td>
                        </template>
                        <v-list>
                          <v-list-item @click="routeToSingleBookingAdmin(admin.id)">
                            <v-list-item-title
                                style="color: black"
                                class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                              View
                              <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider class="tw-mt-3" />
                  </div>
                </div>
              </div>
            </div>
            <div  class="tw-bg-white tw-rounded-md table-container-div section-divider tw-p-4 tw-mb-5 lg:tw-mb-0">
              <div class="table-container">
                <div class="table-container ">
                  <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                    <p :style="{ color: companyData.companyColor }"
                        class="table-container-header mb-0 tw-px-0 ">
                      Trips
                    </p>
                    <p @click="routeToAllTrip" :style="{ color: companyData.companyColor }"
                       class="table-container-header mb-0 tw-px-0 tw-cursor-pointer">
                      View
                    </p>
                  </div>
                  <loader :color="companyData.companyColor" :loading="companyTripLoading" v-if="companyTripLoading"/>
                  <div v-else :class="[userData.userRole === 'transportCompanyBookingAdmin' ? 'route-card': 'booking-admin-card']">
                    <div class="tw-flex tw-flex-col tw-w-full " v-for="(trip, index) in companyTrips" :key="index">
                      <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                        <h6 class="desc-text">{{trip.routeDepartureTerminalName && $vuetify.breakpoint.smAndUp
                            ? trip.routeDepartureTerminalName : trip.routeDepartureTerminalName.substring(0,18)+'...'}}
                            - {{trip.routeDestinationTerminalName && $vuetify.breakpoint.smAndUp
                              ? trip.routeDestinationTerminalName : trip.routeDestinationTerminalName.substring(0,18)+'...'}}</h6>
                        <h6 class="desc-text">{{trip.fleetForThisTrip ? trip.fleetForThisTrip.vehicleRegNumber : "NO FLEET"}}</h6>
                        <v-menu bottom offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <td class="d-flex justify-center desc-text">
                              <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </td>
                          </template>
                          <v-list>
                            <v-list-item @click="routeToSingleTrip(trip.id)">
                              <v-list-item-title
                                  style="color: black"
                                  class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                                View
                                <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <v-divider class="tw-mt-3" />
                    </div>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </v-col>
        <v-col sm="12" lg="3" class="tw-p-0 lg:tw-pl-3 tw-hidden md:tw-flex">
          <v-col sm="12" class="tw-bg-white tw-rounded-md table-container-div ">
            <div class="table-container tw-p-3">
              <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                <p :style="{ color: companyData.companyColor }"
                  class="table-container-header mb-0 tw-px-0">
                  Managers
                </p>
                <p :style="{ color: companyData.companyColor }"
                  class="table-container-header mb-0 tw-px-0 tw-cursor-pointer" @click="routeToAllManagers">
                  View
                </p>
              </div>
              <loader :color="companyData.companyColor" :loading="companyManagerLoading" v-if="companyManagerLoading"/>
              <div v-else class="manager-card">
              <div class="tw-flex tw-flex-col tw-w-full " v-for="(manage, index) in companyManagers" :key="index">
                <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                    <image-avatar :color="companyData.companyColor" :avatar="manage.firstname.charAt(0)" :image="manage.profileImage" />
                  <h6 class="desc-text">{{manage.firstname && $vuetify.breakpoint.smAndUp
                      ? manage.firstname : manage.firstname.substring(0,6)+'...'}}</h6>
                  <h6 class="desc-text">{{manage.terminalAssignedName && manage.terminalAssignedName.length &lt; 6
                      ? manage.terminalAssignedName : manage.terminalAssignedName.substring(0,6)+'...'}}</h6>
                  <v-menu bottom offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <td class="d-flex justify-center desc-text">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </td>
                    </template>
                    <v-list>
                      <v-list-item @click="routeToSingleManager(manage.id)" >
                        <v-list-item-title
                            style="color: black"
                            class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                          View
                          <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-divider class="tw-mt-3" />
              </div>
              </div>
            </div>
          </v-col>
        </v-col>
      </div>

      <div class="tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="9" class="padding-zero">
          <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between tw-mt-7">
            <div class="tw-bg-white tw-rounded-md table-container-div section-divider-3 tw-mb-5 lg:tw-mb-0">
              <div class="table-container tw-p-5 ">
                <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                  <p
                      :style="{ color: companyData.companyColor }"
                      class="table-container-header mb-0 tw-px-0"
                  >
                    Routes
                  </p>
                  <p :style="{ color: companyData.companyColor }"
                     class="table-container-header mb-0 tw-px-0 tw-cursor-pointer" @click="routeToAllRoutes">
                    View
                  </p>
                </div>
                <loader :color="companyData.companyColor" :loading="companyRouteLoading" v-if="companyRouteLoading"/>
                <div v-else class="route-card">
                  <div class="tw-flex tw-flex-col tw-w-full " v-for="(route, index) in companyRoutes" :key="index">
                    <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                      <h6 class="desc-text"> {{ route.departureTerminalState }} &nbsp; - &nbsp; {{route.destinationTerminalState}}</h6>
                      <h6 class="desc-text">{{route.routeStops.length
                          ? route.routeStops[0].stopPoint+'...' : '' }}</h6>
                    </div>
                    <v-divider class="tw-mt-3" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-bg-white tw-rounded-md table-container-div section-divider-2">
              <div class="table-container tw-p-5">
                <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                  <p
                      :style="{ color: companyData.companyColor }"
                      class="table-container-header mb-0 tw-px-0"
                  >
                    Fleet
                  </p>
                  <p :style="{ color: companyData.companyColor }" @click="routeToAllFleet"
                     class="table-container-header mb-0 tw-px-0 tw-cursor-pointer">
                    View
                  </p>
                </div>
                <loader :color="companyData.companyColor" :loading="companyFleetLoading" v-if="companyFleetLoading"/>
                <div v-else class="route-card">
                  <div class="tw-flex tw-flex-col tw-w-full " v-for="(fleet, index) in companyFleets" :key="index">
                    <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                      <img class="img-css" :src="fleet.vehicleImage" alt="image">
                      <h6 class="desc-text">{{ fleet.vehicleType && fleet.vehicleType.length &lt; 6
                          ? fleet.vehicleType : fleet.vehicleType.substring(0,6)+'...'}}</h6>
                      <h6 class="desc-text">{{ fleet.vehicleTagNumber}}</h6>
                      <h6 class="desc-text">{{ fleet.seats.length}}</h6>
                      <h6 class="desc-text">{{ fleet.vehicleBrand}}</h6>
                      <v-menu bottom offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <td class="d-flex justify-center desc-text">
                            <v-btn v-bind="attrs" v-on="on" icon>
                              <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </td>
                        </template>
                        <v-list>
                          <v-list-item @click="routeToSingleFleet(fleet.id)">
                            <v-list-item-title
                                style="color: black"
                                class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                              View
                              <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider class="tw-mt-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col sm="12" lg="3" class="tw-p-0 lg:tw-pl-3">
          <v-col
            sm="12"
            class="tw-bg-white tw-rounded-md table-container-div tw-mt-7"
          >
            <div class="table-container tw-p-3">
              <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
                <p
                    :style="{ color: companyData.companyColor }"
                    class="table-container-header mb-0 tw-px-0"
                >
                  Pilots
                </p>
                <p :style="{ color: companyData.companyColor }"
                   class="table-container-header mb-0 tw-px-0 tw-cursor-pointer" @click="routeToAllPilots">
                  View
                </p>
              </div>
              <loader :color="companyData.companyColor" :loading="companyPilotLoading" v-if="companyPilotLoading"/>
              <div v-else class="pilot-card">
                <div class="tw-flex tw-flex-col tw-w-full " v-for="(pilot, index) in companyPilots" :key="index">
                  <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
                    <img class="img-css" :src="pilot.profileImage" alt="image">
                    <h6 class="desc-text">{{ pilot.firstname && pilot.firstname.length &lt; 6
                        ? pilot.firstname : pilot.firstname.substring(0,6)+'...'}}</h6>
                    <h6 class="desc-text">{{ pilot.fleetAssigned ? pilot.fleetAssigned.vehicleRegNumber : 'UNASSIGNED'}}</h6>
                    <v-menu bottom offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <td class="d-flex justify-center desc-text">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </td>
                      </template>
                      <v-list>
                        <v-list-item @click="routeToSinglePilot(pilot.id)">
                          <v-list-item-title
                              style="color: black"
                              class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                            View
                            <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <v-divider class="tw-mt-3" />
                </div>
              </div>
            </div>
          </v-col>
        </v-col>
      </div>
    </div>
  </v-col>

</template>

<script>
import IconAndText from "../transportCompany/IconAndText";
import tripPlus from "@/assets/icons/trip-plus.svg";
import adminPlus from "@/assets/icons/adminAdd.svg";
import fleetPlus from "@/assets/icons/addFleet.svg";
import pilotPlus from "@/assets/icons/pilot-plus.svg";
import {getCompanyData, getCompanyManagers} from "@/services/api/APIService"
import Loader from "../reuseables/Loader";
import {
  getAllTransportCompanyTrips, getCompanyBookingAdmins
  , getAllCompanyPilot, getAllCompanyFleets, getAllTransportCompanyRoute
} from "@/services/api/APIService";
import ManagerCard from "@/components/reuseables/ManagerCard";
import {mapGetters} from "vuex";
import ImageAvatar from "@/components/reuseables/ImageAvatar.vue";

export default {
  name: "CompanyStaffDashboard",
  components: {ImageAvatar, ManagerCard, Loader, IconAndText },
  data() {
    return {
      tripPlus,
      fleetPlus,
      adminPlus,
      pilotPlus,
      staffData :{},
      userData:{},
      companyManagers:[],
      companyBookingAdmins:[],
      companyTrips:[],
      companyPilots:[],
      companyFleets:[],
      companyRoutes:[],
      companyManagerLoading:false,
      companyAdminLoading : false,
      companyTripLoading: false,
      companyPilotLoading: false,
      companyFleetLoading: false,
      companyRouteLoading: false,
    };
  },
  computed: {
    ...mapGetters('companyProfile',['companyData'])
  },
  methods: {
    newTerminalTab() {
      console.log("Hello World");
    },
    routeToAddTrip(){
      this.sideNav("trips","TerminalManagerTrips")
      sessionStorage.setItem("terminalManagerTripComponent", "addTrip");
      this.$store.dispatch(
          "terminalManagerTripComponent/setCurrentComponent",
          "addTrip"
      );

    },
    routeToAllTrip(){
      this.sideNav("trips","TerminalManagerTrips")
      sessionStorage.setItem("terminalManagerTripComponent", "allTrips");
      this.$store.dispatch(
          "terminalManagerTripComponent/setCurrentComponent",
          "allTrips"
      );
    },
    routeToSingleTrip(tripId){
      this.sideNav("trips","TerminalManagerBookingAdmins")
      sessionStorage.setItem("terminalManagerTripComponent", "singleTrip");
      sessionStorage.setItem("currentTripId", tripId)
      this.$store.dispatch(
          "terminalManagerTripComponent/setCurrentComponent",
          "singleTrip"
      );
    },
    sideNav(side, component){
      this.$store.dispatch("terminalDashboardComponent/setCurrentComponent", side);
      sessionStorage.setItem("terminalDashboardComponent", side);
      this.$router.push({name:component})
      location.reload()
    },
    routeToAddBookingAdmin(){
      this.sideNav("booking admins","TerminalManagerBookingAdmins")
      sessionStorage.setItem("terminalManagerBookingAdminComponent", "addBookingAdmin");
      this.$store.dispatch(
          "terminalManagerBookingAdminComponent/setCurrentComponent",
          "addBookingAdmin"
      );

    },
    routeToAllBookingAdmin(){
      this.sideNav("booking admins","TerminalManagerBookingAdmins")
      sessionStorage.setItem("terminalManagerBookingAdminComponent", "allBookingAdmins");
      this.$store.dispatch(
          "terminalManagerBookingAdminComponent/setCurrentComponent",
          "allBookingAdmins"
      );

    },
    routeToSingleBookingAdmin(adminId){
      this.sideNav("booking admins","TerminalManagerBookingAdmins")
      sessionStorage.setItem("terminalManagerBookingAdminComponent", "singleBookingAdmin");
      sessionStorage.setItem("bookingAdminId", adminId)
      this.$store.dispatch(
          "terminalManagerBookingAdminComponent/setCurrentComponent",
          "singleBookingAdmin"
      );
    },
    routeToAllRoutes(){
      this.sideNav("routes","TerminalManagerRoutes")
      sessionStorage.setItem("terminalManagerRouteComponent", "allRoutes");
      this.$store.dispatch(
          "terminalManagerRouteComponent/setCurrentComponent",
          "allRoutes"
      );

    },
    routeToAllFleet(){
      this.sideNav("fleet","TerminalManagerFleets")
      sessionStorage.setItem("terminalManagerFleetComponent", "allFleets");
      this.$store.dispatch(
          "terminalManagerFleetComponent/setCurrentComponent",
          "allFleets"
      );
    },
    routeToSingleFleet(fleetId){
      this.sideNav("fleet","TerminalManagerFleets")
      sessionStorage.setItem("terminalManagerFleetComponent", "singleFleet");
      sessionStorage.setItem("fleetId", fleetId)
      this.$store.dispatch(
          "terminalManagerFleetComponent/setCurrentComponent",
          "singleFleet"
      );
    },
    routeToAllPilots(){
      this.sideNav("pilots","TerminalManagerPilots")
      sessionStorage.setItem("terminalManagerPilotComponent", "allPilots");
      this.$store.dispatch(
          "terminalManagerPilotComponent/setCurrentComponent",
          "allPilots"
      );
    },
    routeToSinglePilot(pilotId){
      this.sideNav("pilots","TerminalManagerPilots")
      sessionStorage.setItem("terminalManagerPilotComponent", "singlePilot");
      sessionStorage.setItem("pilotId",pilotId)
      this.$store.dispatch(
          "terminalManagerPilotComponent/setCurrentComponent",
          "singlePilot"
      );
    },
    routeToAllManagers(){
      this.sideNav("managers","TerminalManagerManagers")
      sessionStorage.setItem("terminalManagerManagerComponent", "allManagers");
      this.$store.dispatch(
          "terminalManagerManagerComponent/setCurrentComponent",
          "allManagers"
      );
    },
    routeToSingleManager(managerId){
      this.sideNav("managers","TerminalManagerManagers")
      sessionStorage.setItem("terminalManagerManagerComponent", "singleManager");
      sessionStorage.setItem("managerId",managerId)
      this.$store.dispatch(
          "terminalManagerManagerComponent/setCurrentComponent",
          "singleManager"
      );
    },
    async getCompanyManagers(){
      let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyManagerLoading = true
      if (companyData) {
        await getCompanyManagers(companyData.id).then(res => {
          this.companyManagers = res.data
          this.companyManagerLoading = false
        }).catch((err) => {
          console.log(err.response);
          this.companyManagerLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });

        });
      }
    },
   async getCompanyAdmins(){
     let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyAdminLoading = true
     if (companyData) {
       await getCompanyBookingAdmins(companyData.id).then(res => {
         this.companyBookingAdmins = res.data
         this.companyAdminLoading = false
       }).catch((err) => {
         console.log(err.response);
         this.companyAdminLoading = false
         this.$displaySnackbar({
           message: err.response.data.details[0],
           success: false,
         });

       });
     }
    },

    async getCompanyTrips(){
      let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyTripLoading = true
      if (companyData) {
        await getAllTransportCompanyTrips(companyData.id).then(res => {
          this.companyTrips = res.data
          this.companyTripLoading = false
        }).catch((err) => {
          console.log(err.response);
          this.companyTripLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
      }
    },
    async getCompanyPilots(){
      let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyPilotLoading = true
      if (companyData) {
        await getAllCompanyPilot(companyData.id).then(res => {
          this.companyPilots = res.data
          this.companyPilotLoading = false
        }).catch((err) => {
          console.log(err.response);
          this.companyPilotLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
      }
    },

    async getCompanyFleets(){
      let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyFleetLoading = true
      if (companyData) {
        await getAllCompanyFleets(companyData.id).then(res => {
          this.companyFleets = res.data
          this.companyFleetLoading = false
        }).catch((err) => {
          console.log(err.response);
          this.companyFleetLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
      }
    },

    async getCompanyRoutes(){
      let companyData = JSON.parse(sessionStorage.getItem('companyData'))
      this.companyRouteLoading = true
      if (companyData) {
        await getAllTransportCompanyRoute(companyData.id).then(res => {
          this.companyRoutes = res.data
          this.companyRouteLoading = false
        }).catch((err) => {
          console.log(err.response);
          this.companyRouteLoading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
      }
    },
    async getCompany(id){
      await getCompanyData(id).then(async res => {
        this.companyData = res.data
        sessionStorage.setItem("companyData", JSON.stringify(res.data))
        await this.$store.dispatch('companyProfile/setCompanyData', res.data)
        if (!res.data.transportCompanyVerified) {
          this.$displaySnackbar({
            message: "Your company is not verified",
            success: false,
          });
          await this.$router.push({name: 'TransportCompanyLogin'})
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      })
    }
  },
  async created() {
   let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    if (userData){
      this.userData = userData
    }
    if (staffData){
      this.staffData = staffData
       let data = {}
        data.companyEmail = staffData.transportCompanyEmail
        console.log("=========================++++++++++++++++++++")
        await this.$store.dispatch('companyProfile/setCompanyData',data).then(async () => {
          await this.getCompanyAdmins().then(async () =>
              await this.getCompanyTrips().then(async () =>
                  await this.getCompanyManagers().then(async () =>
                      await this.getCompanyRoutes().then(async () =>
                          await this.getCompanyFleets().then(async () =>
                              await this.getCompanyPilots().then())))))
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: available;
  padding: 0 !important;
}

.dashboard {
  .dashboard-header {
    font-family: "Inter",sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(0, 74, 173, 1);
    margin-bottom: 5px;
  }

  .dashboard-sub-header {
    font-family: "Inter",sans-serif;
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.padding-zero {
  padding: 0 !important;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
  }
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Inter",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 0 5px;
}
.tw-cursor-pointer {
  div {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    border: 1px solid white;
    background: #004aad;

    p {
      color: white;
      margin: 0;
    }
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 1);

  p {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

.img-css {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
}
.desc-text{
  font-family: Inter,sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #4F4F4F;
}
.first-section{
  min-height: 30vh;
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}
.manager-card{
  height: 23.5vh;
  max-height: 23.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and(max-width: 1440px) {
    height: 24vh;
    max-height: 24vh;
  }
}
.manager-card::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.manager-card::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.pilot-card{
  height: 24vh;
  max-height: 24vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and(max-width: 1440px) {
    height: 25vh;
    max-height: 25vh;
  }
}
.pilot-card::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.pilot-card::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.route-card{
  height: 25vh;
  max-height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and(max-width: 1440px) {
    height: 26vh;
    max-height: 26vh;
  }
}
.route-card::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.route-card::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.booking-admin-card{
  height: 12vh;
  max-height: 12vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and(max-width: 1440px) {
    height: 14vh;
    max-height: 14vh;

  }
  @media screen and(max-width: 1024px) {
    height: 25vh;
    max-height: 25vh;
  }
}
.booking-admin-card::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.booking-admin-card::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.section-divider{
  display: flex;
  width: 49%!important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

.section-divider-2{
  display: flex;
  width: 55%!important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

.section-divider-3{
  display: flex;
  width: 43%!important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}
</style>
