<template>
  <v-col sm="12" lg="3" class="tw-p-0 lg:tw-pl-3 tw-mt-5 tw-flex md:tw-hidden">
    <v-col sm="12" class="tw-bg-white tw-rounded-md table-container-div ">
      <div class="table-container tw-p-3">
        <div class="tw-flex tw-justify-between tw-w-full tw-pb-3">
          <p :style="{ color: companyData.companyColor }"
             class="table-container-header mb-0 tw-px-0">
            Managers
          </p>
          <p :style="{ color: companyData.companyColor }"
             class="table-container-header mb-0 tw-px-0 tw-cursor-pointer" @click="routeToAllManagers">
            View
          </p>
        </div>
        <loader :color="companyData.companyColor" :loading="companyManagerLoading" v-if="companyManagerLoading"/>

        <div v-else class="manager-card">
          <div class="tw-flex tw-flex-col tw-w-full " v-for="(manage, index) in companyManagers" :key="index">
            <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between tw-mt-3 ">
              <img class="img-css" :src="manage.profileImage" alt="image">
              <h6 class="desc-text">{{manage.firstname && $vuetify.breakpoint.smAndUp
                  ? manage.firstname : manage.firstname.substring(0,6)+'...'}}</h6>
              <h6 class="desc-text">{{manage.terminalAssignedName && manage.terminalAssignedName.length &lt; 6
                  ? manage.terminalAssignedName : manage.terminalAssignedName.substring(0,6)+'...'}}</h6>
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center desc-text">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item @click="routeToSingleManager(manage.id)" >
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center desc-text">
                      View
                      <v-icon class="tw-p-0 tw-m-0" style="height: 20px!important;" :color="companyData.companyColor">mdi-arrow-right</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-divider class="tw-mt-3" />
          </div>
        </div>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import Loader from "@/components/reuseables/Loader";
import {getCompanyManagers} from "@/services/api/APIService";
export default {
  name: "ManagerCard",
  components: {Loader},
  data(){
    return{
      companyManagerLoading:false,
      companyManagers:[],
      companyData: {}
    }
  },
  methods:{
    routeToAllManagers(){
      this.sideNav("managers","TerminalManagerManagers")
      sessionStorage.setItem("terminalManagerManagerComponent", "allManagers");
      this.$store.dispatch(
          "terminalManagerManagerComponent/setCurrentComponent",
          "allManagers"
      );
    },
    routeToSingleManager(managerId){
      this.sideNav("managers","TerminalManagerManagers")
      sessionStorage.setItem("terminalManagerManagerComponent", "singleManager");
      sessionStorage.setItem("managerId",managerId)
      this.$store.dispatch(
          "terminalManagerManagerComponent/setCurrentComponent",
          "singleManager"
      );
    },
    getCompanyManagers(){
      this.companyManagerLoading = true
      getCompanyManagers(this.companyData.id).then(res =>{
        this.companyManagers = res.data
        this.companyManagerLoading = false
      }).catch((err) => {
        console.log(err.response);
        this.companyManagerLoading = false
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });

      });
    },

  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"))

    if (companyData){
      this.companyData = companyData
    }
    await this.getCompanyManagers()
  }
}
</script>

<style scoped lang="scss">
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
  }
}

.manager-card{
  height: 23.5vh;
  max-height: 23.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and(max-width: 1440px) {
    height: 24vh;
    max-height: 24vh;
  }
}
.manager-card::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.manager-card::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.desc-text{
  font-family: Inter,sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #4F4F4F;
}
</style>